<template>
    <div
        class="modal fade"
        :id="props.id"
        tabindex="-1"
        style="z-index: 1000000"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body p-0 text-center">
                    <ul class="list-group">
                        <li
                            data-bs-dismiss="modal"
                            @click="changeLanguage('French')"
                            class="list-group-item tap-react d-flex flex-row justify-content-center align-items-center"
                            style="gap: 10px"
                        >
                            <div style="width: 1.4rem; height: 1.4rem">
                                <img
                                    style="width: 1.3rem"
                                    src="@/assets/flags/fr.png"
                                />
                            </div>
                            Français
                        </li>
                        <li
                            data-bs-dismiss="modal"
                            @click="changeLanguage('English')"
                            class="list-group-item tap-react d-flex flex-row justify-content-center align-items-center"
                            style="gap: 10px"
                        >
                            <div style="width: 1.4rem; height: 1.4rem">
                                <img
                                    style="width: 1.4rem"
                                    src="@/assets/flags/uk.png"
                                />
                            </div>
                            English
                        </li>
                    </ul>
                </div>
                <div>
                    <a data-bs-dismiss="modal" href="#" class="w-100 fw-medium">
                        <div
                            class="px-2 py-2 text-center tap-react rounded-bottom"
                        >
                            {{ t('misc.modal.done') }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, inject } from 'vue';
import { useUserStore } from '@/stores/user-store';
import axios from 'axios';
import { useI18n } from 'vue-i18n';

const moment = inject('moment');
const i18n = useI18n();
const { t } = useI18n();
const userStore = useUserStore();
const props = defineProps(['id']);

const changeLanguage = async (language) => {
    try {
        await axios.post('user/update', {
            language: language,
        });
        if (language == 'French') {
            i18n.locale.value = 'fr';
            moment.locale('fr');
        } else {
            i18n.locale.value = 'en';
            moment.locale('en');
        }
        userStore.fetchUser();
    } catch (err) {
        console.log(err.response);
    }
};
</script>
