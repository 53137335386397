<template>
    <div
        class="modal fade"
        :id="props.id"
        tabindex="-1"
        style="z-index: 1000000"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    {{ t('misc.modal.confirmLogout') }}
                </div>
                <div class="border-top">
                    <div class="d-flex flex-row">
                        <a
                            @click="logout"
                            data-bs-dismiss="modal"
                            class="w-50 py-2 text-danger rounded-bottom tap-react"
                        >
                            <div class="col text-center">
                                {{ t('misc.modal.logout') }}
                            </div>
                        </a>
                        <a
                            data-bs-dismiss="modal"
                            class="w-50 py-2 fw-medium rounded-bottom tap-react"
                        >
                            <div class="col text-center">
                                {{ t('misc.modal.cancel') }}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/stores/user-store';
import axios from 'axios';
const { t } = useI18n();
const userStore = useUserStore();
const router = useRouter();
const props = defineProps(['id']);

const logout = async () => {
    try {
        await axios.post('logout', {
            user_id: userStore.user.id,
        });
        userStore.clearUser();
        router.push('/');
    } catch (err) {
        userStore.clearUser();
        router.push('/');
    }
};
</script>
