<style scoped>
.profile-img-rounded {
    border-radius: 100%;
    aspect-ratio: 1/1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
}
</style>

<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
        style="min-height: 100vh"
    >
        <h1 class="mb-3 text-light">{{ t('bottomBar.more') }}</h1>
        <LogoutModal id="logout-modal" />
        <ChangeLanguageModal id="change-language-modal" />
        <div
            @click="router.push('/more/my-account')"
            class="mb-4 tap-react card shadow-sm p-3 d-flex flex-row align-items-center"
            style="gap: 2rem"
        >
            <div
                class="profile-img-rounded"
                :style="{ 'background-image': `url('${userPhotoLink}')` }"
            ></div>
            <div
                class="d-flex flex-row justify-content-between align-items-center flex-wrap text-break"
            >
                <div>
                    <h5 class="mb-1">
                        {{ user.first_name }}
                        {{ user.last_name }}
                    </h5>
                    <p class="text-muted mb-2">{{ user.email }}</p>
                    <p class="text-muted m-0">
                        {{ t('pages.more.tapToEdit') }}
                        <i
                            class="ms-1 fa fa-chevron-right"
                            style="font-size: 0.8rem"
                        ></i>
                    </p>
                </div>
            </div>
        </div>
        <h5 class="mb-3">
            {{ t('pages.more.mySubscription.mySubscription') }}
        </h5>
        <ul class="list-group mb-4 shadow-sm">
            <div v-if="!user.loading && user.accreditations">
                <div
                    class="rounded-top text-muted card rounded-0 rounded-top p-3 d-flex flex-row justify-content-center align-items-center"
                    style="
                        border-color: var(--bs-list-group-border-color);
                        height: 111px;
                    "
                    v-if="user.accreditations.length == 0"
                >
                    {{ t('pages.more.noSubscriptions') }}
                </div>
            </div>
            <div
                v-if="user.loading || !user.accreditations"
                style="
                    height: 111px;
                    border-color: var(--bs-list-group-border-color);
                "
                class="rounded-top card rounded-0 rounded-top p-3 d-flex flex-row justify-content-center align-items-center"
            >
                <img src="@/assets/loading-icon.gif" style="width: 1.5rem" />
            </div>
            <div class="rounded-top" v-else>
                <li
                    @click="router.push('/more/my-subscription')"
                    v-for="acc in user.accreditations"
                    :key="acc.id"
                    class="list-group-item p-3 tap-react"
                >
                    <span
                        v-if="acc.subscription_active"
                        class="badge text-bg-success"
                        >{{ t('pages.more.active') }}</span
                    >
                    <span
                        v-else-if="!acc.subscription_started"
                        class="badge text-bg-secondary"
                    >
                        {{ t('pages.more.notStarted') }}
                    </span>
                    <span v-if="acc.expired" class="badge text-bg-danger"
                        >{{ t('pages.more.expired') }}
                        {{ acc.expiresInHumans }}</span
                    >
                    <div
                        class="d-flex flex-row align-items-center my-2"
                        style="gap: 1rem"
                    >
                        <h6 class="m-0" v-if="$i18n.locale == 'fr'">
                            <span v-if="acc.boat.brand">
                                {{ acc.boat.brand.name }}
                            </span>
                            {{ acc.boat.identity.name }}
                        </h6>
                        <h6 class="m-0" v-else>
                            <span v-if="acc.boat.brand">
                                {{ acc.boat.brand.name }}
                            </span>
                            {{ acc.boat.identity.name }}
                        </h6>
                    </div>
                    <div
                        v-if="acc.subscription_active"
                        class="d-flex flex-row align-items-center"
                        style="gap: 0rem"
                    >
                        <div
                            style="width: 90px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.more.mySubscription.expires') }}
                        </div>
                        <div>
                            <span v-if="acc.expiresIn.days != 0">
                                {{
                                    t('pages.more.mySubscription.days', {
                                        days: acc.expiresIn.days,
                                    })
                                }}
                                {{ ' ' }}
                            </span>
                            <span v-if="acc.expiresIn.hours != 0">
                                {{
                                    t('pages.more.mySubscription.hours', {
                                        hours: acc.expiresIn.hours,
                                    })
                                }}
                            </span>
                            <span
                                v-if="
                                    !acc.expiresIn.hours &&
                                    !acc.expiresIn.days &&
                                    acc.expiresIn.minutes != 0
                                "
                            >
                                {{ acc.expiresIn.minutes }}
                                {{ t('pages.more.mySubscription.minutes') }}
                            </span>
                            <span
                                v-if="
                                    !acc.expiresIn.hours &&
                                    !acc.expiresIn.days &&
                                    !acc.expiresIn.minutes &&
                                    acc.expiresIn.seconds != 0
                                "
                            >
                                {{ acc.expiresIn.seconds }}
                                {{ t('pages.more.mySubscription.seconds') }}
                            </span>
                        </div>
                    </div>

                    <div
                        v-if="!acc.subscription_started"
                        class="d-flex flex-row align-items-center"
                        style="gap: 0rem"
                    >
                        <div
                            style="width: 160px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.more.startsIn') }}
                        </div>
                        <div>
                            <span v-if="acc.startsIn.days != 0">
                                {{
                                    t('pages.more.mySubscription.days', {
                                        days: acc.startsIn.days,
                                    })
                                }}
                                {{ ' ' }}
                            </span>
                            <span v-if="acc.startsIn.hours != 0">
                                {{
                                    t('pages.more.mySubscription.hours', {
                                        hours: acc.startsIn.hours,
                                    })
                                }}
                            </span>
                            <span
                                v-if="
                                    !acc.startsIn.hours &&
                                    !acc.startsIn.days &&
                                    acc.startsIn.minutes != 0
                                "
                            >
                                {{ acc.startsIn.minutes }}
                                {{ t('pages.more.mySubscription.minutes') }}
                            </span>
                            <span
                                v-if="
                                    !acc.startsIn.hours &&
                                    !acc.startsIn.days &&
                                    !acc.startsIn.minutes &&
                                    acc.startsIn.seconds != 0
                                "
                            >
                                {{ acc.startsIn.seconds }}
                                {{ t('pages.more.mySubscription.seconds') }}
                            </span>
                        </div>
                    </div>
                    <div
                        v-if="!acc.tokens_split_monthly"
                        class="d-flex flex-row align-items-center"
                        style="gap: 0rem"
                    >
                        <div
                            style="width: 160px; font-weight: 500"
                            class="text-muted"
                        >
                            {{ t('pages.more.mySubscription.tokensAvailable') }}
                        </div>
                        <div>
                            {{ acc.current_tokens }} / {{ acc.max_tokens }}
                        </div>
                    </div>
                </li>
            </div>
            <li
                @click="router.push('/more/my-subscription')"
                class="border-top-0 list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                {{ t('pages.more.mySubscription.moreDetails') }}
                <i class="fa-solid fa-chevron-right text-muted"></i>
            </li>
        </ul>
        <h5 class="mb-3">{{ t('pages.more.other') }}</h5>
        <ul class="list-group shadow-sm border-0">
            <li
                @click="router.push('/more/invoices')"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                {{ t('pages.more.invoices.invoices') }}
                <i class="fa-solid fa-chevron-right text-muted"></i>
            </li>
            <a
                v-if="
                    user.subscription_contract &&
                    user.subscription_contract != null
                "
                style="color: inherit !important"
                target="_blank"
                :href="
                    GLOBAL_VAR_BACKEND_STORAGE_URL + user.subscription_contract
                "
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                {{ t('pages.more.subscriptionContract.heading') }}
                <i class="fa-solid fa-chevron-right text-muted"></i>
            </a>
            <li
                @click="router.push('/more/contact-support')"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                {{ t('pages.more.contactSupport') }}
                <i class="fa-solid fa-chevron-right text-muted"></i>
            </li>
            <li
                @click="router.push('/more/feedback')"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                {{ t('pages.more.feedback.feedback') }}
                <i class="fa-solid fa-chevron-right text-muted"></i>
            </li>
            <li
                data-bs-toggle="modal"
                data-bs-target="#change-language-modal"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                <div
                    class="d-flex flex-row align-items-center"
                    style="gap: 0.5rem"
                >
                    <img
                        v-if="user.language == 'French'"
                        src="@/assets/flags/fr.png"
                        style="width: 20px"
                    />
                    <img
                        v-if="user.language == 'English'"
                        src="@/assets/flags/uk.png"
                        style="width: 20px"
                    />
                    <span v-if="user.language == 'French'"> Français </span>
                    <span v-else>
                        {{ user.language }}
                    </span>
                </div>
                <i class="fa-solid fa-chevron-right text-muted"></i>
            </li>
            <li
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
                data-bs-toggle="modal"
                data-bs-target="#logout-modal"
            >
                <div>
                    <i class="fa-solid fa-arrow-right-from-bracket me-1"></i>
                    {{ t('pages.more.logout') }}
                </div>
                <i class="fa-solid fa-chevron-right text-muted"></i>
            </li>
            <li
                @click="router.push('/more/about-app')"
                class="list-group-item tap-react d-flex flex-row justify-content-between align-items-center"
            >
                {{ t('pages.more.aboutApp.aboutApp') }}

                <i class="fa-solid fa-chevron-right text-muted"></i>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user-store';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import LogoutModal from '@/components/LogoutModal';
import ChangeLanguageModal from '@/components/ChangeLanguageModal';
import DefaultUserPhoto from '@/assets/user-placeholder.png';

const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const GLOBAL_VAR_BACKEND_STORAGE_URL = inject('GLOBAL_VAR_BACKEND_STORAGE_URL');
const userPhotoLink = ref(null);
userPhotoLink.value = DefaultUserPhoto;
const { t } = useI18n();

const userStore = useUserStore();
const router = useRouter();
const { user } = storeToRefs(userStore);

if (user.value.passport_photo != null) {
    userPhotoLink.value =
        process.env.VUE_APP_BACKEND_STORAGE_URL + user.value.passport_photo;
}
</script>
